import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import Property from "./pages/Property";
import Profile from "./pages/Profile";
import PropertyDetails from "./pages/PropertyDetails";
import Admin from "./pages/Admin";
import { ToastContainer } from "react-toastify";
import "./styles/main_styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import Investments from "./pages/Investments";
import List from "./pages/List";
import Listings from './pages/Listings';
import ListedPropertyDetails from './pages/ListedPropertyDetails';

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/marketplace" element={<Marketplace />} />
        <Route exact path="/property" element={<Property />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/property-details" element={<PropertyDetails />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/investments" element={<Investments />} />
        <Route exact path="/list/:propertyId" element={<List />} />
        <Route exact path="/listings" element={<Listings />} />
        <Route exact path="/listed-property-details/:listingId" element={<ListedPropertyDetails />} />
      </Routes>
    </>
  );
}

export default App;
