import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

const Listings =()=>{
    const [listings, setListings] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      axios
        .get(process.env.REACT_APP_API_ROOT_URL + "listing")
        .then((response) => {
          setListings(response?.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }, []);
  
    return (
      <>
        <Header />
        <section style={{ paddingTop: "150px" }}>
          <div className="container">
            <h3 className="mb-4">Listings</h3>
            <div className="row">
              {listings &&
                listings.map((ele, index) => (
                  <div className="col-lg-6 col-md-6 col-xl-4 h-100" key={index}>
                    <div className="property__grid__single column__space--secondary">
                      <div className="img__effect">
                        <Link to={"/listed-property-details/" + ele.id}>
                          <img
                            src={ele.images.length > 0 && ele.images[0]}
                            alt="Property"
                            style={{ height: "300px", objectFit: "cover" }}
                          />
                        </Link>
                      </div>
                      <div className="property__grid__single__inner">
                        <h4>{ele.title}</h4>
                        <p className="sub__info">
                          <i className="fa-solid fa-location-dot"></i>{" "}
                          {ele.description}
                        </p>
                        <div className="item__info">
                          <div className="item__info__single">
                            <p>No. Of Shares</p>
                            <h6>{ele.noOfShares}</h6>
                          </div>
                          <div className="item__info__single">
                            <p>Price Per Share</p>
                            <h6>&#8377;{ele.price}</h6>
                          </div>
                        </div>
                        <div className="invest__cta__wrapper">
                          <div className="invest__cta">
                            <button
                            type="button"
                              onClick={() =>
                                navigate("/listed-property-details/" + ele.id)
                              }
                              className="button button--effect"
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </>
    );
}

export default Listings;