import React, { useState, useEffect } from "react";
import { Header } from "../components/Header";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";

const List = () => {
  const schema = yup.object().shape({
    noOfShares: yup
      .number("No. of shares should be a valid number.")
      .integer("No. of shares should be a interger number.")
      .positive("No. of shares should be a positive interger number.")
      .test(
        "checkMaxValue",
        "Shares entered exceed no. of shares available.",
        async function (value) {
          if (value > noOfSharesAvailable) {
            return false;
          }
          // WHEN THE VALUE IS EMPTY RETURN `true` by default
          return true;
        }
      )
      .required("No of shares is required."),
    price: yup
      .number("Price should be a valid number.")
      .positive("Price should be a positive number.")
      .test(
        "is-decimal",
        "Price should be a two decimal number.",
        (val) => {
          if (val !== undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ).required("Price is required."),
  });

  const { propertyId } = useParams();
  const [noOfSharesAvailable, setNoOfSharesAvailable] = useState(0);

  //   const [errors, setErrors] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("rhoof-user"))?.email || "";

    axios
      .post(
        process.env.REACT_APP_API_ROOT_URL + "user/property/" + propertyId,
        {
          email,
        }
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.noOfShares) {
          setNoOfSharesAvailable(res?.data?.noOfShares);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "Please try after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  }, []);

  const handleSubmit = async ({ noOfShares, price }) => {
    const email = JSON.parse(localStorage.getItem("rhoof-user"))?.email || "";
    if (!email) return;
    try {
      await axios.post(process.env.REACT_APP_API_ROOT_URL + "listing", {
        email,
        noOfShares,
        price,
        propertyId,
      });
      setTimeout(() => navigate("/investments"), 1000);
    } catch (error) {
      console.log(error, "123");
      toast.error(
        error?.response?.data?.message || "Please try after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div
      className="wrapper bg__img"
      style={{ backgroundImage: "url(/assets/images/registration-bg.png)" }}
    >
      <Header />
      <section className="registration clear__top">
        <div className="container">
          <div className="registration__area">
            <h4 className="neutral-top">List your property</h4>
            <p className="neutral-top">No. of available shares: {noOfSharesAvailable}</p>
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              initialValues={{ noOfShares: "", price: "" }}
              onSubmit={(values) => {
                console.log(values);
                handleSubmit(values);
              }}
            >
              {({
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <form
                  name="form"
                  className="form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="input input--secondary">
                    <label htmlFor="loginMail">No. of shares :</label>
                    <input
                      type="number"
                      name="noOfShares"
                      id="noOfShares"
                      placeholder="Enter no. of shares"
                      required
                      onChange={handleChange}
                    />
                    {errors.noOfShares ? <>{errors.noOfShares}</> : null}
                  </div>
                  <div className="input input--secondary">
                    <label htmlFor="loginPass">Price per share(&#8377;) :</label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      placeholder="Enter price per share"
                      required
                      onChange={handleChange}
                    />
                    {errors.price ? <>{errors.price}</> : null}
                  </div>
                  <div className="input__button">
                    <button type="submit" className="button button--effect">
                      List
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};

export default List;
