import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Investments = () => {
  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(0);
  const [properties, setProperties] = useState([]);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);

  const options = {
    colors: ["#1FAA5C"],
    chart: {
      type: "area",
      height: 530,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Invested",
        data: [400, 350, 300, 250, 300, 150, 200],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      colors: ["#1FAA5C"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    markers: {
      hover: {
        size: 8,
        strokeWidth: 4,
        colors: ["#ffffff"],
        strokeColors: ["#51CCA9"],
      },
    },
    xaxis: {
      axisTicks: {
        show: true,
      },
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yaxis: {
      show: true,
      opposite: false,
      labels: {
        formatter: function (value) {
          return "$" + value;
        },
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          chart: {
            maxWidth: "100%",
            height: 250,
            sparkline: {
              enabled: false,
            },
          },
        },
      },
    ],
  };

  const signOut = () => {
    try {
      localStorage.removeItem("rhoof-user");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("rhoof-user"))?.email || "";
    if (!email) return;
    axios
      .get(process.env.REACT_APP_API_ROOT_URL + "user/" + email)
      .then((response) => {
        const { user } = response?.data;
        setProperties(user.properties);
        setWalletBalance(user.walletBalance);
        if (Array.isArray(user.properties) && user.properties.length) {
          const amount = user.properties.filter(data=> data.from === 'Marketplace').reduce((acc, property) => {
            acc += property.investedAmount;
            return acc;
          }, 0);
          setTotalInvestedAmount(amount);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Header />
      <div className="dashboard section__space__bottom">
        <div className="container">
          <div className="dashboard__area">
            <div className="row">
              <div className="col-xxl-2">
                <div className="sidebar">
                  <a className="close__sidebar">
                    <i className="fa-solid fa-xmark"></i>
                  </a>
                  <div className="sidenav__wrapper">
                    <ul>
                      <li>
                        <Link to="/investments">
                          <img
                            src="/assets/images/icons/investments.png"
                            alt="Investments"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="/profile">
                          <img
                            src="/assets/images/icons/account.png"
                            alt="Account"
                          />
                        </Link>
                      </li>
                    </ul>
                    <hr />
                    <ul className="logout">
                      <li>
                        <a onClick={signOut} href="#">
                          <img
                            src="/assets/images/icons/logout.png"
                            alt="Logout"
                          />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-10">
                <div className="main__content">
                  <div className="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a
                      href="javascript:void(0)"
                      className="collapse__sidebar__btn"
                    >
                      <i className="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div className="main__content-dashboard">
                    {/* <div className="breadcrumb-dashboard">
                      <h5></h5>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="investment-table">
                          <div className="intro">
                            <h5>Investments</h5>
                          </div>
                          <div className="table-wrapper">
                            <table>
                              <thead>
                                <tr>
                                  <th>Project</th>
                                  <th>From</th>
                                  <th>No. Of Shares</th>
                                  <th>Amount Invested</th>
                                  <th>Date</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                {properties.map((property, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={
                                          "/property-details?property=" +
                                          property.id
                                        }
                                      >
                                        {property.title}
                                      </Link>
                                    </td>
                                    <td>{property.from}</td>
                                    <td>{property.noOfShares}</td>
                                    <td>&#8377;{property.investedAmount}</td>
                                    <td>
                                      {property.investmentDate
                                        ? new Date(
                                            property.investmentDate
                                          ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td>
                                      {property.from === 'Marketplace' && property.noOfShares > 0 ? <button
                                        type="button"
                                        className="button button--effect"
                                        style={{ height: "20px", display: 'flex', alignItems:'center' }}
                                        onClick={() => navigate(
                                          "/list/" + property.id
                                        )}
                                      >
                                        List
                                      </button> : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="investment-sidebar">
                          <div className="statistics">
                            <div className="group">
                              <img
                                src="/assets/images/icons/wallet.png"
                                alt="Wallet"
                              />
                              <div>
                                <h4>&#8377;{totalInvestedAmount}</h4>
                                <p>Total Invested</p>
                              </div>
                            </div>
                            <hr />
                            <div className="group">
                              <img
                                src="/assets/images/icons/wallet.png"
                                alt="Wallet"
                              />
                              <div>
                                <h4>&#8377;{walletBalance}</h4>
                                <p>Wallet Balance</p>
                              </div>
                            </div>
                          </div>
                          <div className="explore">
                            <img
                              src="/assets/images/icons/explore.png"
                              alt="Explore"
                            />
                            <div className="group">
                              <h6>Explore More</h6>
                              <p className="secondary">
                                Investment opportunities
                              </p>
                              <Link
                                to="/marketplace"
                                className="button button--effect"
                              >
                                Explore
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-single__box investment-single-box">
                      <div className="intro">
                        <h5 className="investo">Investment Chart</h5>
                      </div>
                      <ReactApexChart
                        options={options}
                        series={[
                          {
                            name: "series-1",
                            data: [300, 600, 455, 500, 899, 600, 700],
                          },
                        ]}
                        type="area"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investments;
