import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

export const Header = () => {
  const [user, setUser] = useState(null);
  let navigate = useNavigate();

  const handleIsActive = async () => {
    try {
      let userDetails = localStorage.getItem("rhoof-user");
      if (userDetails) {
        setUser(JSON.parse(userDetails));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("rhoof-user");
      setUser(null);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIsActive();
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="/assets/images/logo.png" alt="Logo" className="logo" />
            </a>
            <div className="navbar__out order-2 order-xl-3">
              {user ? (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </span>
                  <button onClick={signOut} className="button text-white">
                    Logout
                  </button>
                </>
              ) : (
                <div className="nav__group__btn">
                  <button
                    className="button button--effect d-none d-sm-block text-white"
                    onClick={() => navigate("/login")}
                  >
                    Login <i className="fa-solid fa-arrow-right-long"></i>
                  </button>
                </div>
              )}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#primaryNav"
                aria-controls="primaryNav"
                aria-expanded="false"
                aria-label="Toggle Primary Nav"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse order-3 order-xl-2"
              id="primaryNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/marketplace">
                    Properties
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/listings">
                    Listings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
