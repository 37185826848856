import React, { useState } from "react";
import { Header } from "../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [verificationKey, setVerificationKey] = useState("");
  const [otp, setOTP] = useState("");
  const [errors, setErrors] = useState({});
  const [isOTPEmailSent, setOTPEmailSent] = useState(false);
  let navigate = useNavigate();

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "auth/email-otp",
        { email }
      );
      console.log(response.data);
      setVerificationKey(response?.data?.verificationKey);
      setOTPEmailSent(true);
      setErrors({ ...errors, email: null });
      toast.success("OTP sent successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error?.response?.data?.code, "123");
      if (error?.response?.data?.code === 400) {
        console.log("as");
        setErrors({
          ...errors,
          email: "Entered email is invalid. Please check.",
        });
      } else {
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleInputOTP = (event) => {
    setOTP(event.target.value);
  };

  const handleOTPSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    console.log("SUBMITTED! ", email);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "auth/verify-otp",
        { email, otp, verificationKey }
      );
      const { user } = response.data;
      if (user) {
        if (!user.email) {
          user["email"] = email;
        }
        console.log("****", user);
        localStorage.setItem("rhoof-user", JSON.stringify(user));
        setTimeout(() => navigate("/"), 1000);
      }
    } catch (error) {
      console.log(error, "123");
      toast.error(
        error?.response?.data?.message || "Please try after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div
      className="wrapper bg__img"
      style={{backgroundImage:"url(/assets/images/registration-bg.png)" }}
    >
      <Header />
      <section className="registration clear__top">
        <div className="container">
          <div className="registration__area">
            <h4 className="neutral-top">Log in</h4>
            <form name="login__form" className="form__login">
              <div className="input input--secondary">
                <label htmlFor="loginMail">Email*</label>
                <input
                  type="email"
                  name="login__email"
                  id="loginMail"
                  placeholder="Enter your email"
                  required
                  onChange={handleInputEmail}
                />
              </div>
              {isOTPEmailSent ? (
                <div className="input input--secondary">
                  <label htmlFor="loginPass">OTP*</label>
                  <input
                    type="otp"
                    name="login__pass"
                    id="loginPass"
                    placeholder="OTP"
                    required
                    onChange={handleInputOTP}
                  />
                </div>
              ) : null}
              <div className="checkbox login__checkbox">
                <label>
                  <input
                    type="checkbox"
                    id="remeberPass"
                    name="remeber__pass"
                    value="remember"
                  />
                  <span className="checkmark"></span>
                  Remember Me
                </label>
              </div>
              <div className="input__button">
                {isOTPEmailSent ? (
                  <button
                    type="button"
                    className="button button--effect"
                    onClick={handleOTPSubmit}
                  >
                    Login
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button button--effect"
                    onClick={handleEmailSubmit}
                  >
                    Continue
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
