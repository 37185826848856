import React from 'react'
import { Link } from 'react-router-dom'

const Admin = () => {
  return (
    <div className='container p-5'>
      <Link className='text-primary' to="/">Back to home</Link>
      <h1>Contact Admin</h1>
      <div className='row mb-3'>
        <div className='col-md-6'>
        <input type="text" placeholder='First Name' className="form-control" required/>
        </div>
        <div className='col-md-6'>
        <input type="text" placeholder='Last Name' className="form-control" required/>
        </div>
      </div>
      <div className='mb-3'>
        <input type="email" placeholder="Enter Your Phone" className="form-control"/>
      </div>
      <div className='mb-3'>
        <input type="email" placeholder="Enter Your Email" className="form-control"/>
      </div>
      <div className='mb-3'>
        <input type="email" placeholder="Enter Your Country" className="form-control"/>
      </div>
      <div className='mb-3'>
        <input type="button" value="Submit" onClick={()=> alert("Admin will contact you.")} className='button text-white'/>
      </div>
    </div>
  )
}

export default Admin