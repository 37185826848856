import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const phoneRegExp =
  /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4,5}\)?)?$/;

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name must have at least 2 characters")
    .max(100, "Name can't be longer than 100 characters")
    .required("Name is required"),
  username: yup
    .string()
    .min(2, "Username must have at least 2 characters")
    .max(10, "Username can't be longer than 10 characters")
    .required("Username is required"),
  countryCode: yup.string(),
  mobile: yup.string().matches(phoneRegExp, "Mobile number is not valid."),
});

const Profile = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    username: "",
    mobile: "",
  });

  const signOut = () => {
    try {
      localStorage.removeItem("rhoof-user");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("rhoof-user"))?.email || "";
    if (!email) return;
    axios
      .get(process.env.REACT_APP_API_ROOT_URL + "user/" + email)
      .then((response) => {
        const {
          user: { name, username, mobile },
        } = response?.data;
        setUserDetails({ name, username, mobile });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const updateUserDetails = (userDetails) => {
    const email = JSON.parse(localStorage.getItem("rhoof-user"))?.email || "";
    userDetails["email"] = email;
    delete userDetails["properties"];
    axios
      .patch(process.env.REACT_APP_API_ROOT_URL + "user", userDetails)
      .then((response) => {
        toast.success("Successful", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Header />
      <div className="dashboard section__space__bottom">
        <div className="container">
          <div className="dashboard__area">
            <div className="row">
              <div className="col-xxl-3">
                <div className="sidebar">
                  <a className="close__sidebar">
                    <i className="fa-solid fa-xmark"></i>
                  </a>
                  <div className="sidenav__wrapper">
                    <ul>
                      <li>
                        <Link to="/investments">
                          <img
                            src="/assets/images/icons/investments.png"
                            alt="Investments"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="/profile">
                          <img
                            src="/assets/images/icons/account.png"
                            alt="Account"
                          />
                        </Link>
                      </li>
                    </ul>
                    <hr />
                    <ul className="logout">
                      <li>
                        <a onClick={signOut} href="#">
                          <img
                            src="/assets/images/icons/logout.png"
                            alt="Logout"
                          />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9">
                <div className="main__content">
                  <div className="collapse__sidebar">
                    <h4>Dashboard</h4>
                    <a
                      href="javascript:void(0)"
                      className="collapse__sidebar__btn"
                    >
                      <i className="fa-solid fa-bars-staggered"></i>
                    </a>
                  </div>
                  <div className="main__content-dashboard">
                    <div className="breadcrumb-dashboard">
                      <h5>Account</h5>
                    </div>
                    <div className="account-info">
                      <div className="account-info__btn-wrapper">
                        <a
                          href="#general"
                          className="account-info__btn account-info__btn-active button button--effect"
                        >
                          General
                        </a>
                      </div>
                      <div className="account-content_wrapper">
                        <div className="account-content" id="general">
                          <Formik
                            enableReinitialize={true}
                            // validationSchema={schema}
                            initialValues={userDetails}
                            onSubmit={(values) => {
                              console.log(values);
                              updateUserDetails(values);
                            }}
                          >
                            {({
                              setFieldValue,
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            }) => (
                              <form
                                noValidate
                                onSubmit={handleSubmit}
                                name="save__from"
                                className="save__form"
                              >
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="input input--secondary">
                                      <label htmlFor="saveName">Name</label>
                                      <input
                                        type="text"
                                        name="name"
                                        id="saveName"
                                        placeholder="Name"
                                        required="required"
                                        value={values.name}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="input input--secondary">
                                      <label htmlFor="saveUsername">
                                        Username
                                      </label>
                                      <input
                                        type="text"
                                        name="username"
                                        id="saveUsername"
                                        placeholder="Username"
                                        required="required"
                                        value={values.username}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="input input--secondary">
                                      <label htmlFor="savePhone">Phone</label>
                                      <input
                                        type="tel"
                                        name="mobile"
                                        id="savePhone"
                                        placeholder="Phone"
                                        required="required"
                                        value={values.mobile}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    type="submit"
                                    className="button button--effect"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
